.pointer {
  cursor: pointer;
}

body.black #page-content,
body.black #page-container {
  background: none;
}

body.black {
  background: black;
}

.nav-orders a {
  border-left: 2px solid transparent;
  padding-left: 13px !important;
  background: rgba(0,0,0,0.05);
}

.nav-orders.red a {
  border-color: #e74c3c;
}

.nav-orders.grey a {
  border-color: #9b9b9b;
}

.nav-orders.pink a {
  border-color: #ff009c;
}

.label-pink {
  background-color: #ff009c;
}

.text-pink, .text-pink:hover, a.text-lpink, a.text-pink:hover, a.text-pink:focus {
  color: #ff009c;
}

.nav-orders.lightyellow a {
  border-color: #ffd200;
}

.label-lightwarning {
  background-color: #ffd200;
}

.text-lightwarning, .text-lightwarning:hover, a.text-lightwarning, a.text-lightwarning:hover, a.text-lightwarning:focus {
  color: #ffd200;
}

.nav-orders.yellow a {
  border-color: #e67e22;
}

.nav-orders.blue a {
  border-color: #3498db;
}

.nav-orders.green a {
  border-color: #27ae60;
}

.row-selected {
  background: #f9ffb7 !important;
}

.row-not-selected .hidden-noselect {
  display: none !important;
}

.pmessage-error {
  background-color: #f2d7d5;
  border-color: #e74c3c;
}

.pmessage-success {
  background-color: #e4f2d5;
  border-color: #64d029;
}

.no-border-bottom {
  border-bottom:0 !important;
}

div.dataTables_filter label {
  float: left;
}

div.dataTables_length label {
  float: right;
}

.edit {
  display: none
}

tr:hover .edit {
  display: inline-block;
}

div.table-responsive {
  width: 100%;
}

#to-top {
  left: auto;
  right: 5px;
}

.text-normal {
  font-weight: normal;
}

.form-group.required .control-label:after {
  content:"*";
  padding-left: 3px;
  color: red;
}

.flag {
  margin-right: 3px;
}

.flag.not-available {
  opacity: 0.2;
}

.multiselect-container {
  padding-bottom: 10px;
}

.navbar-nav li a {
  padding-left: 10px;
  padding-right: 10px;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.text-nowrap {
  white-space: nowrap;
}

.timeline-list .active .timeline-icon {
  background: none;
  border: 2px solid #efefef;
  overflow: hidden;
  left: 100px;
  top: 15px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.timeline-list .active .timeline-icon img {
  position: absolute;
  left: -2px;
  top: -1px;
}

.timeline-list .timeline-time {
  width: 90px;
  padding-top: 10px;
}

.timeline-list .timeline-content {
  margin-left: 150px;
  padding-right: 20px;
}

.timeline-list:after {
  left: 119px;
}

.note-group {
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
}

.timeline-list li:hover .note-group {
  display: block;
}

table.dataTable thead > tr > th {
  padding-left: 8px;
  padding-right: 8px;
}

.table-small {
  background: white;
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
  margin-bottom: 0;
}

.table-small th {
  padding: 8px !important;
}

.no-white-space {
  white-space: nowrap;
}

.most_expensive {
  background: #a5daff !important;
}

.cheapest {
  background: #d0ff86 !important;
}
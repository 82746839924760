/* ProUI */
@import "styles.less";

/* Bootstrap Multiselect */
@import "../../vendor/bootstrap-multiselect/dist/less/bootstrap-multiselect.less";

/* For Firefox */
input[type='number'] {
    -moz-appearance:textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Custom Overrides */
@import "i6.less";
/*
 *  Document   : bs_overwrite.less
 *  Author     : pixelcave
 *
 *  BOOTSTRAP OVERWRITE/EXTEND STYLES
 *
 */

/* Navbar */
.navbar.navbar-default {
    background-color: @color_light_ultra;
}

.navbar.navbar-inverse {
    background-color: @color_navbar_inverse;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
    border-width: 0;
}

/* Typography */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
.h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small,
h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small,
.h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
    font-weight: 300;
    color: #777777;
}

h1, h2, h3 {
    margin-bottom: 15px;
}

.text-primary,
.text-primary:hover,
a,
a:hover,
a:focus,
a.text-primary,
a.text-primary:hover,
a.text-primary:focus {
    color: @color_highlight;
}

.text-danger,
.text-danger:hover,
a.text-danger,
a.text-danger:hover,
a.text-danger:focus {
    color: #e74c3c;
}

.text-warning,
.text-warning:hover,
a.text-warning,
a.text-warning:hover,
a.text-warning:focus {
    color: #e67e22;
}

.text-success,
.text-success:hover,
a.text-success,
a.text-success:hover,
a.text-success:focus {
    color: #27ae60;
}

.text-info,
.text-info:hover,
a.text-info,
a.text-info:hover,
a.text-info:focus {
    color: #3498db;
}

.text-muted,
.text-muted:hover,
a.text-muted,
a.text-muted:hover,
a.text-muted:focus {
    color: #999999;
}

.text-light,
.text-light:hover,
a.text-light,
a.text-light:hover,
a.text-light:focus {
    color: #ffffff;
}

b, strong {
    font-weight: 600;
}

ul,
ol {
    padding-left: 30px;
}

.list-li-push li {
    margin-bottom: 10px;
}

p {
    line-height: 1.6;
}

article p {
    font-size: 16px;
    line-height: 1.8;
}

.well {
    background-color: #f9f9f9;
    border: 1px solid #eeeeee;
}

.page-header {
    border-bottom-width: 1px;
    border-bottom-color: #dddddd;
    margin: 30px 0 20px;
}

.sub-header {
    margin: 10px 0 20px;
    padding: 10px 0;
    border-bottom: 1px dotted #dddddd;
}

blockquote {
    border-left-width: 3px;
}

blockquote {
    margin: 20px 0;
    padding: 30px 60px 30px 20px;
    position: relative;
    width: 100%;
    border-color: @color_light_main;
}

blockquote:before {
    display: block;
    content: "\201C";
    font-family: serif;
    font-size: 96px;
    position: absolute;
    right: 10px;
    top: -30px;
    color: @color_light_main;
}

blockquote.pull-right:before {
    left: 10px;
    right: auto;
}

/* Forms */
label {
    font-weight: 600;
}

fieldset legend {
    font-size: 16px;
    padding: 30px 0 10px;
    border-bottom: 2px solid @color_light_main;
}

input[type="file"] {
    padding-top: 7px;
}

input[type="text"].form-control,
input[type="password"].form-control,
input[type="email"].form-control,
textarea.form-control {
    -webkit-appearance: none;
}

.form-control {
    font-size: 13px;
    padding: 6px 8px;
    max-width: 100%;
    margin: 1px 0;
    color: @color_dark;
    border-color: @color_light_secondary;
}

.form-control-borderless .form-control,
.form-control-borderless .input-group-addon,
.form-control-borderless,
.form-control-borderless:focus {
    border: transparent !important;
}

.input-group {
    margin-top: 1px;
    margin-bottom: 1px;
}

.input-group .form-control {
    margin-top: 0;
}

.form-control:focus {
    border-color: @color_highlight;
}

.help-block {
    color: #777777;
    font-weight: 400;
}

.input-group-addon {
    min-width: 45px;
    text-align: center;
    background-color: #ffffff;
    border-color: @color_light_secondary;
}

.form-horizontal .control-label {
    margin-bottom: 5px;
}

.form-bordered {
    margin: -15px -15px -1px;
}

.modal-body .form-bordered {
    margin-bottom: -20px;
}

.form-bordered fieldset legend {
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.form-bordered .form-group {
    margin: 0;
    border: none;
    padding: 15px;
    border-bottom: 1px dashed @color_light_main;
}

.form-bordered .form-group.form-actions {
    background-color: @color_light_ultra;
    border-bottom: none;
}

.form-horizontal.form-bordered .form-group {
    padding-left: 0;
    padding-right: 0;
}

.form-bordered .help-block {
    margin-bottom: 0;
}

.has-success .form-control,
.has-warning .form-control,
.has-error .form-control {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.has-success .help-block,
.has-success .control-label,
.has-success .input-group-addon,
.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .radio,
.has-success .radio-inline {
    color: #27ae60;
}

.has-success .form-control,
.has-success .input-group-addon {
    border-color: #27ae60;
    background-color: #ffffff;
}

.has-success .form-control:focus {
    border-color: #166638;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .input-group-addon,
.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .radio,
.has-warning .radio-inline {
    color: #e67e22;
}

.has-warning .form-control,
.has-warning .input-group-addon {
    border-color: #e67e22;
    background-color: #ffffff;
}

.has-warning .form-control:focus {
    border-color: #b3621b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .input-group-addon,
.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .radio,
.has-error .radio-inline {
    color: #e74c3c;
}

.has-error .form-control,
.has-error .input-group-addon {
    border-color: #e74c3c;
    background-color: #ffffff;
}

.has-error .form-control:focus {
    border-color: #c0392b;
}

/* Form Wizards */
.wizard-steps {
    border-bottom: 1px solid @color_light_main;
    margin-bottom: 20px;
}

.form-bordered .wizard-steps {
    margin-bottom: 0;
}

.wizard-steps .row {
    margin: 0;
}

.wizard-steps .row div {
    padding: 15px 0;
    font-size: 15px;
    text-align: center;
}

.form-bordered .wizard-steps .row div {
    padding-top: 10px;
}

.wizard-steps span {
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border: 1px solid @color_highlight;
    border-radius: 50px;
}

.wizard-steps div.done span,
.wizard-steps div.active span {
    background-color: @color_highlight;
    color: #ffffff;
}

.wizard-steps div.done span {
    opacity: 0.25;
    filter: alpha(opacity=25);
}

.wizard-steps div.active span {
    opacity: 1;
    filter: alpha(opacity=100);
}

/* Form Select Switches */
.switch {
    margin: 1px 0;
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.switch input {
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
}

.switch span {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    border-radius: 28px;
    background-color: #f9f9f9;
    border: 1px solid #dddddd;
    -webkit-transition: background-color 0.35s;
    transition: background-color 0.35s;
}

.switch span:after {
    content: "";
    position: absolute;
    left: 2px;
    top: 1px;
    bottom: 1px;
    width: 24px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 50%;
    -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.05);
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}

.switch input:checked + span:after {
    left: 26px;
    border: none;
    -webkit-box-shadow: -2px 0 3px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 0 3px rgba(0, 0, 0, 0.1);
}

.switch input:checked + span {
    background-color: #eeeeee;
}

.switch-default span {
    border-color: @color_light_secondary;
}

.switch-default input:checked + span {
    background-color: @color_light_secondary;
}

.switch-primary span {
    border-color: @color_highlight;
}

.switch-primary input:checked + span {
    background-color: @color_highlight;
}

.switch-info span {
    border-color: #7abce7;
}

.switch-info input:checked + span {
    background-color: #7abce7;
}

.switch-success span {
    border-color: #aad178;
}

.switch-success input:checked + span {
    background-color: #aad178;
}

.switch-warning span {
    border-color: #f7be64;
}

.switch-warning input:checked + span {
    background-color: #f7be64;
}

.switch-danger span {
    border-color: #ef8a80;
}

.switch-danger input:checked + span {
    background-color: #ef8a80;
}

.lt-ie9 .switch input {
    position: static;
    opacity: 1;
    filter: alpha(opacity=100);
}

.lt-ie9 .switch span,
.lt-ie9 .switch span:after {
    display: none !important;
}

/* Tables */
.table.table-vcenter th,
.table.table-vcenter td {
    vertical-align: middle;
}

.table-options {
    padding: 6px 0;
}

.table thead > tr > th {
    font-size: 18px;
    font-weight: 600;
}

.table thead > tr > th > small {
    font-weight: 400;
    font-size: 75%;
}

.table thead > tr > th,
.table thead > tr > td,
.table tfoot > tr > th,
.table tfoot > tr > td {
    padding-top: 14px;
    padding-bottom: 14px;
}

.table tfoot > tr > th,
.table tfoot > tr > td {
    background-color: @color_light_ultra;
}

.table-borderless tbody > tr > th,
.table-borderless tbody > tr > td {
    border-top-width: 0;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td,
.table tbody + tbody,
.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
    border-color: @color_light_main;
}

.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    background-color: @color_light_main;
}

/* List Group */
.list-group-item {
    border-color: @color_light_main;
}

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
    background-color: @color_highlight;
    border-color: @color_highlight;
}

a.list-group-item.active .list-group-item-text,
a.list-group-item.active:hover .list-group-item-text,
a.list-group-item.active:focus .list-group-item-text {
    color: #ffffff;
}

a.list-group-item:hover,
a.list-group-item:focus {
    background-color: @color_light_ultra;
}

a.list-group-item.active > .badge {
    background: url('@{assets_folder}/template/ie8_opacity_dark_40.png') repeat;
    background: rgba(0, 0, 0, 0.4);
    color: #ffffff;
}

/* Navs */
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    color: #fff;
    background-color: @color_highlight;
}

.nav > li i {
    font-size: 14px;
}

.nav-pills > .active > a > .badge {
    color: @color_highlight;
}

.nav-pills.nav-icons > li > a i {
    margin-right: 10px;
}

.nav-pills.nav-icons > li.active > a i,
.nav-pills.nav-icons > li.active > a:hover i,
.nav-pills.nav-icons > li.active > a:focus i {
    color: #ffffff;
}

.nav-stacked > li > a {
    margin: 4px 0 0;
}

.nav .caret,
.nav a:hover .caret,
.nav a:focus .caret {
    border-top-color: @color_highlight;
    border-bottom-color: @color_highlight;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: @color_light_ultra;
}

.nav-tabs {
    border-bottom-color: @color_light_main;
}

.nav-tabs > li {
    margin-bottom: 0;
}

.nav-tabs > li > a {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: -1px;
}

.nav-tabs > li > a:hover {
    border-color: @color_light_main;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: @color_dark;
    border-color: @color_light_main;
    border-bottom-color: transparent;
}

.nav-pills > li.active > a > .badge {
    background: url('@{assets_folder}/template/ie8_opacity_dark_20.png') repeat;
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

.dropdown-menu {
    padding: 0;
    font-size: 13px;
    border-color: @color_light_secondary;
    -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.1);
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
}

.dropdown-menu > li > a {
    padding: 6px 10px;
}

.dropdown-menu i {
    opacity: 0.2;
    filter: alpha(opacity=20);
    line-height: 17px;
}

.dropdown-menu a:hover i {
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.dropdown-menu .divider {
    margin: 2px 0;
    padding: 0 !important;
    background-color: #f0f0f0;
}

li.dropdown-header {
    padding: 5px 10px;
    color: @color_dark;
    background-color: @color_light_ultra;
    border-top: 1px solid @color_light_main;
    border-bottom: 1px solid @color_light_main;
}

.dropdown-menu li:first-child.dropdown-header {
    border-top: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.dropdown-menu.dropdown-custom {
    min-width: 200px;
}

.dropdown-menu.dropdown-custom > li {
    padding: 8px 10px;
    font-size: 12px;
}

.dropdown-menu.dropdown-custom > li > a {
    border-radius: 3px;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -3px;
    margin-left: 0;
}

.dropdown-submenu:hover > .dropdown-menu {
    display: block;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.collapse.in .dropdown.open .dropdown-menu {
    display: block;
    position: static;
    margin: 0 0 0 15px;
    left: auto;
    top: auto;
}

.navbar-fixed-top .dropdown-menu {
    max-height: 400px;
    overflow-y: auto;
}

.pagination > li > a,
.pagination > li > span  {
    color: @color_highlight;
    margin-left: 5px;
    margin-right: 5px;
    border: none !important;
    border-radius: 25px !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: @color_highlight;
}

.pager > li > a,
.pager > li > span {
    border-color: @color_light_main;
}

.pager > li > a:hover,
.pagination > li > a:hover {
    background-color: @color_highlight;
    border-color: @color_highlight;
    color: #ffffff;
}

.pager > li.disabled > a:hover {
    border-color: @color_light_main;
}

/* Horizontal Navigation */
.nav-horizontal {
    padding: 10px 7px 1px;
    margin: 0;
    list-style: none;
}

.nav-horizontal li {
    display: inline-block;
    margin: 0 3px 9px;
}

.nav-horizontal a {
    display: block;
    min-width: 140px;
    border-radius: 3px;
    font-weight: bold;
    text-align: left;
    padding: 8px 10px;
    background-color: @color_light_ultra;
}

.nav-horizontal a:hover,
.nav-horizontal li.active a {
    background-color: @color_highlight;
    text-decoration: none;
    color: #ffffff;
}

.nav-horizontal a:focus {
    text-decoration: none;
}

.nav-horizontal a:hover i,
.nav-horizontal li.active a i {
    color: #ffffff;
    opacity: .6;
    filter: alpha(opacity=60);
}

.nav-horizontal i {
    display: inline-block;
    font-size: 14px;
    color: @color_light_secondary;
    margin-right: 5px;
}

/* Popover */
.popover-title {
    background: none;
    border: none;
    font-size: 17px;
    font-weight: 600;
}

/* Tooltip */
.tooltip {
    z-index: 1051;
}

.tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100);
}

.tooltip-inner {
    padding: 4px 6px;
    background-color: #000000;
    color: #ffffff;
}

.tooltip.top .tooltip-arrow,
.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
    border-top-color: #000000;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #000000;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #000000;
}

.tooltip.bottom .tooltip-arrow,
.tooltip.bottom-left .tooltip-arrow,
.tooltip.bottom-right .tooltip-arrow {
    border-bottom-color: #000000;
}

/* Breadcrumps */
.breadcrumb {
    background-color: #ffffff;
}

.breadcrumb i {
    font-size: 14px;
}

.breadcrumb-top {
    margin: -10px -5px 10px;
    padding: 7px 10px;
    border-top: 1px solid @color_light_main;
    border-bottom: 1px solid @color_light_secondary;
    font-size: 12px;
}

.content-header + .breadcrumb-top,
.breadcrumb-top + .content-header {
    margin-top: -11px;
}

.breadcrumb > li + li:before {
    content: "\203a";
}

/* Progress Bars */
.progress,
.progress-bar {
    height: 20px;
    line-height: 20px;
}

.progress-bar-danger {
    background-color: #e74c3c;
}

.progress-bar-warning {
    background-color: #f39c12;
}

.progress-bar-success {
    background-color: #2ecc71;
}

.progress-bar-info {
    background-color: #3498db;
}

/* Modals */
.modal-content {
    border-radius: 3px;
}

.modal-header {
    padding: 15px 15px 14px;
    border-bottom: 1px solid #eeeeee;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.modal-title {
    font-weight: 300;
}

.modal-body {
    padding: 20px 15px;
}

.modal-body .nav-tabs {
    margin: 0 -15px 15px;
    padding: 0 5px !important;
}

.modal-footer {
    margin-top: 0;
    padding: 14px 15px 15px;
    border-top: 1px solid #eeeeee;
    background-color: #f9f9f9;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

/* Buttons */
.btn {
    margin: 1px 0;
    background-color: #ffffff;
}

.btn .gi,
.btn .hi,
.btn .si,
.btn .fi {
    line-height: 1;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 0.4;
    filter: alpha(opacity=40);
}

.block-options .btn,
.input-group .btn,
.modal-content .btn {
    margin-top: 0;
    margin-bottom: 0;
}

.btn-default {
    background-color: @color_btn_default_bg;
    border-color: @color_btn_default_b;
    color: @color_dark;
}

.btn-default.btn-alt {
    background-color: #ffffff;
}

.btn-default:hover {
    background-color: @color_btn_default;
    border-color: @color_btn_default_bh;
}

.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default.active,
.btn-default.active:hover,
.btn-default.active:focus,
.open .btn-default.dropdown-toggle,
.open .btn-default.dropdown-toggle:hover,
.open .btn-default.dropdown-toggle:focus,
.open .btn-default.dropdown-toggle.focus {
    background-color: @color_btn_default_ac;
    border-color: @color_btn_default_ac;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
.btn-default[disabled]:active:focus,
.btn-default[disabled].active:focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default:active:focus,
fieldset[disabled] .btn-default.active:focus {
    background-color: @color_btn_default;
    border-color: @color_btn_default;
}

.btn-primary {
    background-color: @color_btn_primary_bg;
    border-color: @color_btn_primary;
    color: #ffffff;
}

.btn-primary.btn-alt {
    background-color: #ffffff;
    color: @color_btn_primary;
}

.btn-primary:hover {
    background-color: @color_btn_primary;
    border-color: @color_btn_primary_bh;
    color: #ffffff;
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary.active,
.btn-primary.active:hover,
.btn-primary.active:focus,
.open .btn-primary.dropdown-toggle,
.open .btn-primary.dropdown-toggle:hover,
.open .btn-primary.dropdown-toggle:focus,
.open .btn-primary.dropdown-toggle.focus {
    background-color: @color_btn_primary;
    border-color: @color_btn_primary;
    color: #ffffff;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
.btn-primary[disabled]:active:focus,
.btn-primary[disabled].active:focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary:active:focus,
fieldset[disabled] .btn-primary.active:focus {
    background-color: @color_btn_primary;
    border-color: @color_btn_primary;
    color: #ffffff;
}

.btn-danger {
    background-color: #ef8a80;
    border-color: #e74c3c;
    color: #ffffff;
}

.btn-danger.btn-alt {
    background-color: #ffffff;
    color: #e74c3c;
}

.btn-danger:hover {
    background-color: #e74c3c;
    border-color: #9c3428;
    color: #ffffff;
}

.btn-danger:focus,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger.active,
.btn-danger.active:hover,
.btn-danger.active:focus,
.open .btn-danger.dropdown-toggle,
.open .btn-danger.dropdown-toggle:hover,
.open .btn-danger.dropdown-toggle:focus,
.open .btn-danger.dropdown-toggle.focus {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #ffffff;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
.btn-danger[disabled]:active:focus,
.btn-danger[disabled].active:focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger:active:focus,
fieldset[disabled] .btn-danger.active:focus {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #ffffff;
}

.btn-warning {
    background-color: #f7be64;
    border-color: #f39c12;
    color: #ffffff;
}

.btn-warning.btn-alt {
    background-color: #ffffff;
    color: #f39c12;
}

.btn-warning:hover {
    background-color: #f39c12;
    border-color: #b3730c;
    color: #ffffff;
}

.btn-warning:focus,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning.active,
.btn-warning.active:hover,
.btn-warning.active:focus,
.open .btn-warning.dropdown-toggle,
.open .btn-warning.dropdown-toggle:hover,
.open .btn-warning.dropdown-toggle:focus,
.open .btn-warning.dropdown-toggle.focus {
    background-color: #f39c12;
    border-color: #f39c12;
    color: #ffffff;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
.btn-warning[disabled]:active:focus,
.btn-warning[disabled].active:focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning:active:focus,
fieldset[disabled] .btn-warning.active:focus {
    background-color: #f39c12;
    border-color: #f39c12;
    color: #ffffff;
}

.btn-success {
    background-color: #aad178;
    border-color: #7db831;
    color: #ffffff;
}

.btn-success.btn-alt {
    background-color: #ffffff;
    color: #7db831;
}

.btn-success:hover {
    background-color: #7db831;
    border-color: #578022;
    color: #ffffff;
}

.btn-success:focus,
.btn-success.focus,
.btn-success:active,
.btn-success:active:hover,
.btn-success:active:focus,
.btn-success.active,
.btn-success.active:hover,
.btn-success.active:focus,
.open .btn-success.dropdown-toggle,
.open .btn-success.dropdown-toggle:hover,
.open .btn-success.dropdown-toggle:focus,
.open .btn-success.dropdown-toggle.focus {
    background-color: #7db831;
    border-color: #7db831;
    color: #ffffff;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
.btn-success[disabled]:active:focus,
.btn-success[disabled].active:focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success:active:focus,
fieldset[disabled] .btn-success.active:focus {
    background-color: #7db831;
    border-color: #7db831;
    color: #ffffff;
}

.btn-info {
    background-color: #7abce7;
    border-color: #3498db;
    color: #ffffff;
}

.btn-info.btn-alt {
    background-color: #ffffff;
    color: #3498db;
}

.btn-info:hover {
    background-color: #3498db;
    border-color: #2875a8;
    color: #ffffff;
}

.btn-info:focus,
.btn-info.focus,
.btn-info:active,
.btn-info:active:hover,
.btn-info:active:focus,
.btn-info.active,
.btn-info.active:hover,
.btn-info.active:focus,
.open .btn-info.dropdown-toggle,
.open .btn-info.dropdown-toggle:hover,
.open .btn-info.dropdown-toggle:focus,
.open .btn-info.dropdown-toggle.focus {
    background-color: #3498db;
    border-color: #3498db;
    color: #ffffff;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
.btn-info[disabled]:active:focus,
.btn-info[disabled].active:focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info:active:focus,
fieldset[disabled] .btn-info.active:focus {
    background-color: #3498db;
    border-color: #3498db;
    color: #ffffff;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link.btn-icon:hover,
.btn-link.btn-icon:focus {
    color: @color_highlight;
}

.btn-link.btn-icon {
    color: #999999;
}

.btn-link.btn-icon:hover,
.btn-link.btn-icon:focus {
    text-decoration: none;
}

.block-options .btn {
    border-radius: 15px;
    padding-right: 8px;
    padding-left: 8px;
    min-width: 30px;
    text-align: center;
}

/* Panels */
.panel {
    margin-bottom: 20px;
}

.panel-heading {
    padding: 15px;
}

.panel-title {
    font-size: 14px;
}

.panel-default > .panel-heading {
    background-color: #f9f9f9;
}

.panel-group {
    margin-bottom: 20px;
}

/* Pre, Code */
pre {
    background: #151515;
    overflow: scroll;
}

code {
    border: 1px solid #fad4df;
    margin: 1px 0;
    display: inline-block;
}

.btn code {
    display: inline;
    margin: 0;
}

/* Alerts */
.alert {
    border-top-width: 0;
    border-right-width: 2px;
    border-bottom-width: 0;
    border-left-width: 2px;
}

.alert-danger {
    color: #e74c3c;
    background-color: #ffd1cc;
    border-color: #ffb8b0;
}

.alert-danger .alert-link {
    color: #e74c3c;
}

.alert-warning {
    color: #e67e22;
    background-color: #ffe4cc;
    border-color: #ffd6b2;
}

.alert-warning .alert-link {
    color: #e67e22;
}

.alert-success {
    color: #27ae60;
    background-color: #daf2e4;
    border-color: #b8e5cb;
}

.alert-success .alert-link {
    color: #27ae60;
}

.alert-info {
    color: #3498db;
    background-color: #dae8f2;
    border-color: #b8d2e5;
}

.alert-info .alert-link {
    color: #3498db;
}

.alert-dismissable .close {
    top: -5px;
    right: -25px;
}

.close {
    text-shadow: none;
}

/* Alternative Alerts */
.alert.alert-alt {
    margin: 0 0 2px;
    padding: 5px;
    font-size: 12px;
    border-width: 0;
    border-left-width: 2px;
}

.alert.alert-alt small {
    opacity: 0.75;
    filter: alpha(opacity=75);
}

.alert-alt.alert-dismissable .close {
    right: 0;
}

.alert-alt.alert-dismissable .close:hover {
    color: #ffffff;
}

.alert-danger.alert-alt {
    border-color: #e74c3c;
}

.alert-warning.alert-alt {
    border-color: #e67e22;
}

.alert-success.alert-alt {
    border-color: #27ae60;
}

.alert-info.alert-alt {
    border-color: #3498db;
}

.sidebar-content .alert.alert-alt {
    margin-left: -10px;
    padding-left: 10px;
    background: transparent;
    color: #ffffff;
}

#sidebar-alt .sidebar-content .alert.alert-alt {
    margin-left: 0;
    margin-right: -10px;
    padding-left: 0;
    padding-right: 10px;
}

#sidebar-alt .sidebar-content .alert.alert-alt {
    border-width: 0;
    border-right-width: 2px;
}

/* Labels, Badges */
.label,
.badge {
    font-weight: normal;
    font-size: 90%;
}

.label {
    padding: 1px 4px;
}

.badge {
    background: url('@{assets_folder}/template/ie8_opacity_dark_30.png') repeat;
    background: rgba(0, 0, 0, 0.3);
    padding: 3px 6px;
}

.label-danger {
    background-color: #e74c3c;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #ff5542;
}

.label-warning {
    background-color: #e67e22;
}

.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #ff8b26;
}

.label-success {
    background-color: #27ae60;
}

.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #2cc76c;
}

.label-info {
    background-color: #2980b9;
}

.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #2f92d4;
}

.label-primary {
    background-color: @color_highlight;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #5ac5e0;
}

.label-default {
    background-color: #999999;
}

.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #777777;
}

/* Carousel */
.carousel-control.left,
.carousel-control.right,
.carousel-control.left.no-hover:hover,
.carousel-control.right.no-hover:hover {
    background: none;
}

.carousel-control.left:hover,
.carousel-control.right:hover {
    background: url('@{assets_folder}/template/ie8_opacity_dark_30.png') repeat;
    background: rgba(0, 0, 0, 0.30);
}

.carousel-control span {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    display: inline-block;
}

.carousel-control i {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
}

/* Bottom Margin */
p, .table, .alert, .carousel {
    margin-bottom: 20px;
}

/* Removing shadows and radius */
.navbar-form-custom .form-control:hover,
.navbar-form-custom .form-control:focus,
.navbar-form,
.navbar-collapse,
.form-control,
.form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus,
.popover,
.progress,
.progress-bar,
.btn.active,
.open .btn.dropdown-toggle,
.panel {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-form-custom .form-control,
.navbar,
.dropdown-menu,
.tooltip-inner,
.breadcrumb,
.alert.alert-alt {
    border-radius: 0;
}